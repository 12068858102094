.wrapper {
  display: flex;
}

.dark {
  .tokenName {
    border-color: #515F7F;
    background: #515F7F;
    color: #fff;

    &:after {
      background: #9AA0AD;
    }
  }
}
.tokenName {
  background: #FDFDFD;
  border-radius: 7px 0 0 7px;
  min-width: 45px;
  flex-shrink: 0;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  color: #1C2230;
  font-size: 18px;
  border: 1px solid #D2D6E8;
  border-right: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: 1px;
    height: 30px;
    display: block;
    position: absolute;
    background: #D2D6E8;    
    right: 0;
    top: 5px;
  }
}

.input {
  border-radius: 0;
  border-left: 0 ;
  border-right: 0;
  flex-grow: 1;

  &::placeholder {
    color: #9AA0AD;
    font-size: 18px;
  }
}

.wrapper .button {
  background-color: #ae528b;
  border-radius: 0 7px 7px 0;
  border: 0;
  color: #fff;
  font-weight: 600;
  font-size: 19px;
  padding: 5px 16px;
  flex-shrink: 0;
  outline: 0;
  transform: translateX(-1px);
  height: 40px;
  box-shadow: none;
  text-transform: capitalize;
}


