.body {
  padding: 18px 0 21px 0;

  &.sidePaddings {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-height: 265px;
    background-color: #ffffff;
    padding-left: 18px;
    text-align: center;
    padding-right: 18px;
  }
}

.loader {
  margin: auto;
  :global {
    .rotate {
      height: 65px;
      width: 65px;
    }
  }
}

.errorText {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 17px;
}

.overhead {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}

.rowHeading {
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;

  &.mt {
    margin-top: 12px;
  }
}

.rowHeadingValue {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.row {
  display: flex;
  padding: 7px 50px;
  justify-content: space-between;

  &.accent {
    background: #f7f7f9;
  }
}

.rowValue {
  font-size: 18px;
  line-height: 32px;
}

.inputContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputTitle {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 6px;
}

.input {
  background: #fdfdfd;
  border: 1px solid #ae528b;
  font-size: 20px;
  outline: none;
  border-radius: 7px;
  height: 40px;
  text-align: center;
}

.inputDescription {
  margin-top: 12px;
  font-size: 17px;
  line-height: 30px;
}

.buttonContainer {
  margin-top: 31px;
  display: flex;
  justify-content: center;
}

.dark {
  .overhead,
  .row,
  .rowHeading,
  .inputTitle,
  .inputDescription,
  .errorText,
  .errorDetails {
    color: #ffffff;
  }

  .input {
    color: #ffffff;
    background-color: #515f7f;
  }

  .body {
    background-color: #302e2d;
  }

  .row {
    &.accent {
      background-color: #1f2535;
    }
  }
}
