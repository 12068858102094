.wrapper {
  display: flex;
  align-items: center;
}

.text {
  color: #7f89a7;
  font-weight: 600;
  font-size: 16px;
}

.tooltipIcon {
  margin-right: 8px;
}

.tooltipText {
  width: 100%;
  height: 100%;
}
