.modal {
	:global {
		.MuiPaper-root {
			border-radius: 10px;
			@media screen and (max-width: 1000px) {
				margin: 10px;
			}
		}
	}
}

.container {
	max-width: 400px;
}

.title {
	text-align: center;
	margin-top: 30px;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;

	@media screen and (max-width: 1000px) {
		margin-top: 20px;
	}
}

.description {
	margin: 0 auto;
	max-width: 300px;
	text-align: center;
	padding: 0 10px;
}

.list {
	margin-bottom: 40px;
	margin-left: 60px;
	margin-right: 60px;
	padding: 0;
	@media screen and (max-width: 1000px) {
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
  }
}

.item {
	list-style: none;
	padding: 15px 10px 10px 0;
	border-bottom: 1px solid rgba(164, 169, 184, 0.29);
	@media screen and (max-width: 1000px) {
		padding: 10px 0;
  }
}

.itemInner {
	text-decoration: none;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 17px;
	line-height: 20px;
	flex-wrap: wrap;
}

.logo {
	width: 45px;
	height: 45px;
	border-radius: 35px;
	margin-right: 0px;
	@media screen and (max-width: 1000px) {
		margin-right: 15px;
		width: 35px;
		height: 35px;
	}
}

.arrow {
	margin-left: auto;
	width: 28px;
	height: 38px;
}

.list li:last-child {
	border-bottom: 0
}

.close {
	cursor: pointer;
	position: absolute;
	top: 7px;
	right: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	background-color: transparent;
	outline: none;
	border: 0;
	transition: opacity .2s ease-out;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}
}

.loading {
	text-align: center;
	margin-top: 60px;
}

.loading img {
	width: 50px;
	height: 50px;
}
