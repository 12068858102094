.dialog {
  :global {
    .MuiPaper-root {
      border-radius: 18px;
      background-color: transparent;
      width: 100%;
      max-width: 492px;
      margin: 15px;
    }
  }
}

.container {
  width: 100%;
  max-width: 492px;

  .disconnect {
    cursor: pointer;
    background: transparent;
    border: 1px solid #D2D6E8;
    color: #ae528b;
    font-size: 17px;
    padding: 8px 14px;
    border-radius: 99px;
    outline: none;
    line-height: initial;
    min-width: auto;
    margin-left: auto;
    letter-spacing: normal;
    text-transform: none;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
  }
}

.header {
  position: relative;
  background: #1C2230;
  display: flex;
  min-height: 78px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(249.54deg,#ff97d6 44.37%,#f8b994 82.84%);
    display: block;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #F1F3F9;
  padding: 30px 35px 38px 35px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  @media screen and (max-width: 1000px) {
    padding: 14px;
  }
}
