.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 22px;
}

.leftBlock {
  display: flex;
  align-items: center;
  min-height: 35px;
}

.imageWrapper {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}

.image {
  height: 35px;
  width: 35px;
  object-fit: contain;
}

.value {
  font-weight: 600;
}

.arrowWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}

.arrowWrapperPercentValue {
  min-width: 90px;
}
.arrowImg {
  margin-left: 5px;
  margin-right: 5px;
  width: 15px;
}

