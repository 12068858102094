.checkbox-container {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  
  input {
    position: absolute;
    width: 0;
    background: transparent;
    height: 0;
    border: 0;
    outline: none;
    opacity: 0;

    &:checked + .icon {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  input:checked + .icon {
    background-color: #7F89A7;
  }

  .icon {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border: 1px solid #7F89A7;
    transition: all .2s ease-out;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/checkbox-icon.svg);
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease-out;
    }
  }

}

.children {
  margin-left: 12px;
}
