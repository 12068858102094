.wrap {
  display: flex;
  align-items: center;
}

.trigger {
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  display: flex;
  background-color: #ae528b;
  border: 0;
  border: 1px solid #ae528b;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  padding: 0;
  height: 37px;
  padding-left: 13px;
  align-items: center;

  &.dark {
    background: #3a4154;
    border-color: #3a4154;

    .hint {
      color: #ffffff;
    }
  }
}

.dots {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  background-color: #3aaa45;
}

.hint {
  font-size: 15px;
  position: relative;
  margin-right: 9px;
  text-decoration-line: underline;
  color: #ffffff;
}

.icon {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid #ae528b;
  box-shadow: inset 0px 0px 0px 3px #ae528b;
}
