.dialog {
  :global {
    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 100px;

      @media screen and (max-width: 1000px) {
        align-items: center;
        margin-top: 0;
      }
    }

    .MuiPaper-root {
      border-radius: 18px;
      width: 100%;
      max-width: 510px;
      margin: 15px;
      max-height: calc(100% - 164px);
      background: transparent;

      @media screen and (max-width: 1000px) {
        max-height: calc(100% - 64px);
      }
    }
  }

  p {
    margin: 0;
  }
}

.header {
  position: relative;
  background: #1c2230;

  display: flex;
  z-index: 3;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(249.54deg,#ff97d6 44.37%,#f8b994 82.84%);
    display: block;
  }
}

.cardContent {
  background-color: #ffffff;
  padding-top: 22px;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.dark {
  .cardContent {
    color: #fff;
    background: #302e2d;
  }
  .footer{
    background-color: #1c2230;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.imageOmContainer {
  width: 39px;
  height: 39px;
  margin-right: 12px;
}

.imageWithdraw {
  margin: 0 auto 15px;
  object-fit: contain;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 36px;
  padding: 0 0 14px 0;
}

.tableHeader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 20px;
  line-height: 32px;
  padding: 0 30px;
  color: #7f89a7;
  margin-bottom: 4px;
  & > div {
    &:not(:first-child) {
      text-align: center;
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #f1f3f9;
  border-radius: 13px;
  font-size: 18px;
  line-height: 29px;
  padding: 8px 30px;
  margin-bottom: 22px;
  & > div {
    &:not(:first-child) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.body {
  padding: 0;
}

.operationTitle {
  font-size: 21px;
  line-height: 35px;
  margin-bottom: 4px;
  text-align: center;
  padding-left: 11px;
  padding-right: 11px;
}

.value {
  font-size: 21px;
  color: #ae528b;
  text-align: center;
  margin-bottom: 7px;
  font-weight: 700;
}

.descriptionText {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 19px;

  &:not(:last-child) {
    margin-bottom: 22px;
  }
  span {
    color: #ae528b;
    font-weight: 700;
  }
}

.buttonContainer {
  margin-top: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;

  .withdrawButton {
    border-radius: 100px;
    width: 100%;
    margin: 0 auto;
    min-height: 44px;
    max-width: 294px;
    padding: 5px 16px;
    color: #ffffff;
    text-decoration: none;
    text-transform: none;
    font-weight: 700;
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.error {
  text-align: center;
  margin-bottom: 16px;
  color: #ae528b;
}

.footer {
  background: #F1F3F9;
  padding: 14px 22px 21px 22px;
}
