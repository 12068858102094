.container {
  display: flex;
  background: #f7f7f9;
  border: 1px solid #384053;
  box-sizing: border-box;
  border-radius: 10px;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0;
  background-color: transparent;
  min-width: 38px;
  min-height: 33px;
  padding: 3px 8px;
  box-sizing: border-box;
  font-size: 17px;
  color: #1c2230;

  &.active {
    background: #d2d2d6;
    border: 1px solid #384053;
    box-sizing: border-box;
    border-radius: 8px;
  }
}

.dark {
  background-color: #1c2230;

  .item {
    background-color: #1c2230;
    color: #ffffff;
    &.active {
      background: #3d465b;
    }
  }
}
