.tokenTitle {
  margin-bottom: 14px;
}

.line {
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}
