.container {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  background: #FFFFFF;
  border: 2px solid #D2D6E8;
  box-sizing: border-box;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 12px 22px 12px 16px;
  margin-bottom: 18px;

  &.dark {
    background: #434E69;
    border-color: transparent;
    .value, & {
      color: #FFFFFF;
    }

    .button {
      border-color: #ffffff;
      color: #ffffff;
    }
  }
}

.logo {
  width: 47px;
  height: 47px;
  object-fit: contain;
}

.subIconsItem {
  max-width: 20px;
  &:not(:last-child) {
    margin-right: 4px;
  }
}

.subIcons {
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.logoLabel {
  position: absolute;
  left: calc(100% - 6px);
  top: -17px;
  font-size: 15px;
  line-height: 28px;
  color: #ae528b;
}

.value {
  font-size: 17px;
  line-height: 27px;
  color: #1C2230;
  margin-bottom: 4px;
}

.usd {
  font-size: 14px;
  line-height: 22px;
  color: #3AAA45;
}

.column {
  display: flex;
  align-items: flex-start;

  .button {
    margin: auto 0 auto auto;
    min-width: 115px;
    text-transform: none;
    font-weight: 400;
    padding: 3px 5px;
    font-size: 17px;
    border-radius: 100px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
