.button.wrapper {
  border-radius: 100px;
  width: 100%;
  max-width: 295px;
  min-height: 44px;
  padding: 5px 16px;
  text-transform: none;
  font-weight: 700;

  &.gray {
    background: #7F89A7 !important;
  }

  span {
    font-size: 19px;
    font-weight: bold;
    text-transform: initial;
    color: #FDFDFD;
  }
}
