.body {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-bottom: 28px;
  min-height: 400px;
}

.title {
  font-size: 19px;
  padding: 15px 0;
  line-height: 32px;
  text-align: center;
}

.table {
  display: table;
  width: 100%;
}

.tableRow {
  display: table-row;

  &.tableHeading {
    .tableCell {
      font-size: 18px;
      color: #ae528b;

      &:nth-child(1) {
        width: 8%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 17%;
      }
    }
  }

  &:nth-child(2n + 1) {
    background-color: #f7f7f9;
  }

  &:not(.tableHeading) {
    .tableCell {
      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}

.tableCell {
  display: table-cell;
  font-size: 15px;
  line-height: 32px;
  padding: 10px;
  vertical-align: middle;

  &:nth-child(1) {
    padding-left: 15px;
    padding-right: 5px;
  }
  &:nth-child(3) {
    padding-right: 15px;
  }
}

.pagination {
  margin: auto auto 0;
  margin-top: 25px;
}

.errorTitle {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
  text-align: center;
}

.errorText {
  text-align: center;
  padding: 17px 20px 20px 20px;
}

.loader {
  margin: auto;
}

.dark {
  .body,
  .title,
  .table {
    background-color: #302e2d;
  }

  .title {
    color: #ffffff;
  }

  .tableRow {
    &:nth-child(2n + 1) {
      background-color: #1f2535;
    }

    &:not(.tableHeading) {
      .tableCell {
        color: #ffffff;
      }
    }
  }
}
