.modal {
  :global {
    .MuiPaper-root {
      border-radius: 10px;
      background: transparent;

      * {
        box-sizing: border-box;
      }

      @media screen and (max-width: 1000px) {
        margin: 10px;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #282626;
  padding: 16px 24px;
}

.title {
  color: #ffffff;
}

.text {
  @extend .title;
  text-align: center;
  font-size: 18px;
}
