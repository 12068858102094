.wrapper {
  flex: 0 0 68px;

  @media screen and (max-width: 1000px) {
    display: none;

    &.active {
      display: flex;
      z-index: 50;
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow: auto;

      .container {
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      & + div {
        display: none;
      }
    }

    .burgerButton {
      position: fixed;
      top: 15px;
      right: 15px;
    }
  }
}

.container {
  border-radius: 0px 0px 20px 0px;
  margin-bottom: 30px;
  position: sticky;
  z-index: 1;
  top: 0px;
  padding-bottom: 30px;

  @media screen and (max-width: 1000px) {
    position: static;
    border-radius: 0;
    margin-bottom: 0;
  }
}
