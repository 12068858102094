.body {
  padding-top: 18px;
  background-color: #ffffff;
}

.bottomContent {
  background: #e7eaf2;
  padding-bottom: 21px;
  border-radius: 18px;

  .checkboxWrapper {
    padding-top: 35px;
    margin: 0px 50px 27px 50px;

    .checkboxLabel {
      font-size: 17px;
      line-height: 21px;
      word-break: break-word;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 26px;
}

.topBlock {
  padding: 0 34px;
  margin-bottom: 28px;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 9px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d6e8;
  }
  line-height: 34px;
  font-size: 18px;
}

.rowLabel {
  padding-right: 10px;
}

.rowValue {
  font-size: 18px;
  color: #ae528b;
}

.bottomBlock {
  background: #e7eaf2;
  border-radius: 18px;
  padding: 22px 135px;
}

.checkbox {
  margin-bottom: 22px;
}

.checkboxText {
  width: 319px;
}

.inputLabel {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  background: #fdfdfd;

  height: 40px;
  font-size: 18px;
  border-radius: 7px;
  padding: 0 12px;
  margin-bottom: 25px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.dark {
  .row {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(127, 137, 167, 0.3);
    }
  }

  .title,
  .rowLabel,
  .bottomContent {
    color: #ffffff;
  }

  .checkboxWrapper {
    .checkboxLabel {
      color: #ffffff;
    }
  }
  &.body {
    background-color: #302e2d;
  }

  .bottomContent {
    background-color: #1f2535;
  }
}
