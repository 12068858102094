.connectButton {
  &.connectButtonHelpClass {
    background: transparent;
    border: 1px solid #ae528b;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 17px;
    margin-left: 36px;
    color: #ae528b;
    padding: 3px 18px 3px 14px;
    text-transform: none;
    font-weight: 400;
    overflow: hidden;
    transition: all 0.2s ease-out !important;
    position: relative;

    .connectButtonBody {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 8px;

      path {
        transition: all 0.2s ease-out;
      }
    }

    &:hover {
      color: #ffffff;
      svg path {
        stroke: #ffffff;
      }
      &:after {
        opacity: 1;
      }
    }

    &:active {
      opacity: 0.7;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s ease-out;
      opacity: 0;
      background: linear-gradient(
        180deg,
        #fb0f6b 30.26%,
        #b63d98 115.29%,
        #b53d99 116.78%,
        #547ed8 117.99%,
        #8e57b2 119.77%
      );
    }
  }
}
