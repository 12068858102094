.wallet {
  background: #F1F3F9;
  border-radius: 13px;
  color: #1C2230;
  font-size: 20px;
  padding: 8px 20px 4px 20px;
  width: auto;
  min-width: 250px;
  max-width: max-content;
  font-weight: normal;
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 10px;
    min-width: 240px;
  }

  p {
    margin: 0;
  }
}

.balanceValue {
  font-weight: 600;
  overflow-wrap: break-word;
}

.balanceUcd {
  margin-top: 6px;
  color: #7E8C9B;
  font-weight: 600;
  line-height: 150%;
}
