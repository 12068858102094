.mt-1 {
  margin-top: 1rem;
}

.mt-1half {
  margin-top: 1.5rem;
}

.mt-half {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.button-row {
  display: flex;
  flex-direction: column;

  &__button {
    & + .button-row__button {
      margin-top: 1rem;

      @media (min-width: 768px) {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
  }

  @media (min-width: 768px) {
    justify-content: center;
    flex-direction: row;
  }
}

.detail-item {
  > p {
    margin-top: 0.4rem;
    word-wrap: break-word;
  }
  + .detail-item {
    margin-top: 1.4rem;
  }
}

.ico-price-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 0.4rem;

  .ico-price-list__item {
    + .ico-price-list__item {
      margin-top: 0.4rem;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mdao-alt-100 {
  color: #9aa0ad;
}

.pre-line {
  white-space: pre-line;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}
