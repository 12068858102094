.container {
  display: flex;
  align-items: center;
  width: 100%;
  background: #FDFDFD;
  border: 1px solid #D2D6E8;
  box-sizing: border-box;
  border-radius: 7px;
  height: 40px;
  font-size: 18px;
  outline: none;
  color: #000000;

  input {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 5px 14px;
    border-radius: inherit;
    background: transparent;
    outline: none;
    color: inherit;
    font: inherit;

    &::placeholder {
      font: inherit;
      color: #9AA0AD;
    }
  }

  &.dark {
    border-color: #515F7F;
    background: #515F7F;
    color: #ffffff;
  }
}

.icon {
  display: flex;
  flex-shrink: 0;
  padding-left: 18px;
  padding-right: 12px;
}

.rightLabel {
  position: relative;
  width: 119px;
  padding: 5px;
  font-size: 19px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #D2D6E8;
  }
}
