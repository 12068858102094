.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 24px;
  background-color: #ffffff;
}

.title {
  font-size: 21px;
  line-height: 39px;
  text-align: center;
  margin-bottom: 26px;
}

.icon {
  width: 65px;
  height: 65px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43af64;
  margin-bottom: 7px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 41px;
  line-height: 34px;
  font-size: 18px;

  &.accent {
    background: #f7f7f9;
  }
}

.rowLabel {
  padding-right: 10px;
}

.rowValue {
  font-size: 18px;
  font-weight: 700;
  color: #ae528b;
}

.dark {
  .title,
  .row,
  .rowHeading {
    color: #ffffff;
  }

  &.body {
    background-color: #302e2d;
  }

  .row {
    &.accent {
      background-color: #1f2535;
    }
  }
}
