.container {
  text-align: center;
  width: 100%;
  max-width: 312px;
  background: #F1F3F9;
  padding: 17px;
  border-radius: 14px;
  margin: 20px auto 40px;

  &.dark {
    background: #302e2d;
  }
}

.txID {
  font-size: 17px;
  line-height: 30px;
  color: inherit;
}

.icon {
  margin-left: 14px;
  height: 16px;
}
