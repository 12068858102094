.imageContainer {
  position: relative;
}

.image {
  min-height: 35px;
  min-width: 35px;
  object-fit: contain;
}

.imagePetard {
  position: absolute;
  top: -15px;
  right: 15px;
  font-size: 24px;
  width: 31px;
  height: 29px;
  transform: rotate(-19.32deg);
}
