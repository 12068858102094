//https://reflexgrid.com/

:root {
  --reflex-columns: 12;
  --reflex-grid-spacing: 15px;
  --reflex-xs: 576px;
  --reflex-sm: 768px;
  --reflex-md: 992px;
  --reflex-lg: 1200px;
  --reflex-xlg: 1600px;
  --reflex-xxs-max: 575px;
  --reflex-xs-max: 767px;
  --reflex-sm-max: 991px;
  --reflex-md-max: 1199px;
  --reflex-lg-max: 1599px;
}
.fb-grid {
  .container,
  .container-full {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .container .grid,
  .container-full .grid {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .container {
      max-width: 1600px;
    }
  }

  .grid {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
    position: relative;
    letter-spacing: -0.31em;
    *letter-spacing: normal;
    word-spacing: -0.43em;
    list-style-type: none;
  }

  .grid::before,
  .grid::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
  }

  [class*='col-'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
    position: relative;
    width: 100%;
    vertical-align: top;
    padding: 15px;
    display: inline-block;
    *display: inline;
    zoom: 1;
  }

  [class*='col-']::before,
  [class*='col-']::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
  }

  [class*='col-'] .grid {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: -15px;
  }

  .col-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .col-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .col-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .col-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .col-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .col-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .col-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .col-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }

  @media (min-width: 576px) {
    .col-xs-12 {
      width: 100%;
      *width: 99.9%;
    }
    .col-xs-11 {
      width: 91.66666667%;
      *width: 91.56666667%;
    }
    .col-xs-10 {
      width: 83.33333333%;
      *width: 83.23333333%;
    }
    .col-xs-9 {
      width: 75%;
      *width: 74.9%;
    }
    .col-xs-8 {
      width: 66.66666667%;
      *width: 66.56666667%;
    }
    .col-xs-7 {
      width: 58.33333333%;
      *width: 58.23333333%;
    }
    .col-xs-6 {
      width: 50%;
      *width: 49.9%;
    }
    .col-xs-5 {
      width: 41.66666667%;
      *width: 41.56666667%;
    }
    .col-xs-4 {
      width: 33.33333333%;
      *width: 33.23333333%;
    }
    .col-xs-3 {
      width: 25%;
      *width: 24.9%;
    }
    .col-xs-2 {
      width: 16.66666667%;
      *width: 16.56666667%;
    }
    .col-xs-1 {
      width: 8.33333333%;
      *width: 8.23333333%;
    }
  }

  @media (min-width: 768px) {
    .col-sm-12 {
      width: 100%;
      *width: 99.9%;
    }
    .col-sm-11 {
      width: 91.66666667%;
      *width: 91.56666667%;
    }
    .col-sm-10 {
      width: 83.33333333%;
      *width: 83.23333333%;
    }
    .col-sm-9 {
      width: 75%;
      *width: 74.9%;
    }
    .col-sm-8 {
      width: 66.66666667%;
      *width: 66.56666667%;
    }
    .col-sm-7 {
      width: 58.33333333%;
      *width: 58.23333333%;
    }
    .col-sm-6 {
      width: 50%;
      *width: 49.9%;
    }
    .col-sm-5 {
      width: 41.66666667%;
      *width: 41.56666667%;
    }
    .col-sm-4 {
      width: 33.33333333%;
      *width: 33.23333333%;
    }
    .col-sm-3 {
      width: 25%;
      *width: 24.9%;
    }
    .col-sm-2 {
      width: 16.66666667%;
      *width: 16.56666667%;
    }
    .col-sm-1 {
      width: 8.33333333%;
      *width: 8.23333333%;
    }
  }

  @media (min-width: 992px) {
    .col-md-12 {
      width: 100%;
      *width: 99.9%;
    }
    .col-md-11 {
      width: 91.66666667%;
      *width: 91.56666667%;
    }
    .col-md-10 {
      width: 83.33333333%;
      *width: 83.23333333%;
    }
    .col-md-9 {
      width: 75%;
      *width: 74.9%;
    }
    .col-md-8 {
      width: 66.66666667%;
      *width: 66.56666667%;
    }
    .col-md-7 {
      width: 58.33333333%;
      *width: 58.23333333%;
    }
    .col-md-6 {
      width: 50%;
      *width: 49.9%;
    }
    .col-md-5 {
      width: 41.66666667%;
      *width: 41.56666667%;
    }
    .col-md-4 {
      width: 33.33333333%;
      *width: 33.23333333%;
    }
    .col-md-3 {
      width: 25%;
      *width: 24.9%;
    }
    .col-md-2 {
      width: 16.66666667%;
      *width: 16.56666667%;
    }
    .col-md-1 {
      width: 8.33333333%;
      *width: 8.23333333%;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-12 {
      width: 100%;
      *width: 99.9%;
    }
    .col-lg-11 {
      width: 91.66666667%;
      *width: 91.56666667%;
    }
    .col-lg-10 {
      width: 83.33333333%;
      *width: 83.23333333%;
    }
    .col-lg-9 {
      width: 75%;
      *width: 74.9%;
    }
    .col-lg-8 {
      width: 66.66666667%;
      *width: 66.56666667%;
    }
    .col-lg-7 {
      width: 58.33333333%;
      *width: 58.23333333%;
    }
    .col-lg-6 {
      width: 50%;
      *width: 49.9%;
    }
    .col-lg-5 {
      width: 41.66666667%;
      *width: 41.56666667%;
    }
    .col-lg-4 {
      width: 33.33333333%;
      *width: 33.23333333%;
    }
    .col-lg-3 {
      width: 25%;
      *width: 24.9%;
    }
    .col-lg-2 {
      width: 16.66666667%;
      *width: 16.56666667%;
    }
    .col-lg-1 {
      width: 8.33333333%;
      *width: 8.23333333%;
    }
  }

  @media (min-width: 1600px) {
    .col-xlg-12 {
      width: 100%;
      *width: 99.9%;
    }
    .col-xlg-11 {
      width: 91.66666667%;
      *width: 91.56666667%;
    }
    .col-xlg-10 {
      width: 83.33333333%;
      *width: 83.23333333%;
    }
    .col-xlg-9 {
      width: 75%;
      *width: 74.9%;
    }
    .col-xlg-8 {
      width: 66.66666667%;
      *width: 66.56666667%;
    }
    .col-xlg-7 {
      width: 58.33333333%;
      *width: 58.23333333%;
    }
    .col-xlg-6 {
      width: 50%;
      *width: 49.9%;
    }
    .col-xlg-5 {
      width: 41.66666667%;
      *width: 41.56666667%;
    }
    .col-xlg-4 {
      width: 33.33333333%;
      *width: 33.23333333%;
    }
    .col-xlg-3 {
      width: 25%;
      *width: 24.9%;
    }
    .col-xlg-2 {
      width: 16.66666667%;
      *width: 16.56666667%;
    }
    .col-xlg-1 {
      width: 8.33333333%;
      *width: 8.23333333%;
    }
  }

  .col-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }

  @media (min-width: 576px) {
    .col-xs-auto {
      -ms-flex: 1 0 auto;
      -webkit-flex: 1 0 0px;
      flex: 1 0 0px;
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .col-sm-auto {
      -ms-flex: 1 0 auto;
      -webkit-flex: 1 0 0px;
      flex: 1 0 0px;
      width: auto;
    }
  }

  @media (min-width: 992px) {
    .col-md-auto {
      -ms-flex: 1 0 auto;
      -webkit-flex: 1 0 0px;
      flex: 1 0 0px;
      width: auto;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-auto {
      -ms-flex: 1 0 auto;
      -webkit-flex: 1 0 0px;
      flex: 1 0 0px;
      width: auto;
    }
  }

  @media (min-width: 1600px) {
    .col-xlg-auto {
      -ms-flex: 1 0 auto;
      -webkit-flex: 1 0 0px;
      flex: 1 0 0px;
      width: auto;
    }
  }

  .order-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }

  @media (min-width: 576px) {
    .order-xs-12 {
      -ms-flex-order: 12;
      -webkit-order: 12;
      order: 12;
    }
    .order-xs-11 {
      -ms-flex-order: 11;
      -webkit-order: 11;
      order: 11;
    }
    .order-xs-10 {
      -ms-flex-order: 10;
      -webkit-order: 10;
      order: 10;
    }
    .order-xs-9 {
      -ms-flex-order: 9;
      -webkit-order: 9;
      order: 9;
    }
    .order-xs-8 {
      -ms-flex-order: 8;
      -webkit-order: 8;
      order: 8;
    }
    .order-xs-7 {
      -ms-flex-order: 7;
      -webkit-order: 7;
      order: 7;
    }
    .order-xs-6 {
      -ms-flex-order: 6;
      -webkit-order: 6;
      order: 6;
    }
    .order-xs-5 {
      -ms-flex-order: 5;
      -webkit-order: 5;
      order: 5;
    }
    .order-xs-4 {
      -ms-flex-order: 4;
      -webkit-order: 4;
      order: 4;
    }
    .order-xs-3 {
      -ms-flex-order: 3;
      -webkit-order: 3;
      order: 3;
    }
    .order-xs-2 {
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .order-xs-1 {
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
    .order-xs-0 {
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
    }
  }

  @media (min-width: 768px) {
    .order-sm-12 {
      -ms-flex-order: 12;
      -webkit-order: 12;
      order: 12;
    }
    .order-sm-11 {
      -ms-flex-order: 11;
      -webkit-order: 11;
      order: 11;
    }
    .order-sm-10 {
      -ms-flex-order: 10;
      -webkit-order: 10;
      order: 10;
    }
    .order-sm-9 {
      -ms-flex-order: 9;
      -webkit-order: 9;
      order: 9;
    }
    .order-sm-8 {
      -ms-flex-order: 8;
      -webkit-order: 8;
      order: 8;
    }
    .order-sm-7 {
      -ms-flex-order: 7;
      -webkit-order: 7;
      order: 7;
    }
    .order-sm-6 {
      -ms-flex-order: 6;
      -webkit-order: 6;
      order: 6;
    }
    .order-sm-5 {
      -ms-flex-order: 5;
      -webkit-order: 5;
      order: 5;
    }
    .order-sm-4 {
      -ms-flex-order: 4;
      -webkit-order: 4;
      order: 4;
    }
    .order-sm-3 {
      -ms-flex-order: 3;
      -webkit-order: 3;
      order: 3;
    }
    .order-sm-2 {
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .order-sm-1 {
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
    .order-sm-0 {
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
    }
  }

  @media (min-width: 992px) {
    .order-md-12 {
      -ms-flex-order: 12;
      -webkit-order: 12;
      order: 12;
    }
    .order-md-11 {
      -ms-flex-order: 11;
      -webkit-order: 11;
      order: 11;
    }
    .order-md-10 {
      -ms-flex-order: 10;
      -webkit-order: 10;
      order: 10;
    }
    .order-md-9 {
      -ms-flex-order: 9;
      -webkit-order: 9;
      order: 9;
    }
    .order-md-8 {
      -ms-flex-order: 8;
      -webkit-order: 8;
      order: 8;
    }
    .order-md-7 {
      -ms-flex-order: 7;
      -webkit-order: 7;
      order: 7;
    }
    .order-md-6 {
      -ms-flex-order: 6;
      -webkit-order: 6;
      order: 6;
    }
    .order-md-5 {
      -ms-flex-order: 5;
      -webkit-order: 5;
      order: 5;
    }
    .order-md-4 {
      -ms-flex-order: 4;
      -webkit-order: 4;
      order: 4;
    }
    .order-md-3 {
      -ms-flex-order: 3;
      -webkit-order: 3;
      order: 3;
    }
    .order-md-2 {
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .order-md-1 {
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
    .order-md-0 {
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
    }
  }

  @media (min-width: 1200px) {
    .order-lg-12 {
      -ms-flex-order: 12;
      -webkit-order: 12;
      order: 12;
    }
    .order-lg-11 {
      -ms-flex-order: 11;
      -webkit-order: 11;
      order: 11;
    }
    .order-lg-10 {
      -ms-flex-order: 10;
      -webkit-order: 10;
      order: 10;
    }
    .order-lg-9 {
      -ms-flex-order: 9;
      -webkit-order: 9;
      order: 9;
    }
    .order-lg-8 {
      -ms-flex-order: 8;
      -webkit-order: 8;
      order: 8;
    }
    .order-lg-7 {
      -ms-flex-order: 7;
      -webkit-order: 7;
      order: 7;
    }
    .order-lg-6 {
      -ms-flex-order: 6;
      -webkit-order: 6;
      order: 6;
    }
    .order-lg-5 {
      -ms-flex-order: 5;
      -webkit-order: 5;
      order: 5;
    }
    .order-lg-4 {
      -ms-flex-order: 4;
      -webkit-order: 4;
      order: 4;
    }
    .order-lg-3 {
      -ms-flex-order: 3;
      -webkit-order: 3;
      order: 3;
    }
    .order-lg-2 {
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .order-lg-1 {
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
    .order-lg-0 {
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
    }
  }

  @media (min-width: 1600px) {
    .order-xlg-12 {
      -ms-flex-order: 12;
      -webkit-order: 12;
      order: 12;
    }
    .order-xlg-11 {
      -ms-flex-order: 11;
      -webkit-order: 11;
      order: 11;
    }
    .order-xlg-10 {
      -ms-flex-order: 10;
      -webkit-order: 10;
      order: 10;
    }
    .order-xlg-9 {
      -ms-flex-order: 9;
      -webkit-order: 9;
      order: 9;
    }
    .order-xlg-8 {
      -ms-flex-order: 8;
      -webkit-order: 8;
      order: 8;
    }
    .order-xlg-7 {
      -ms-flex-order: 7;
      -webkit-order: 7;
      order: 7;
    }
    .order-xlg-6 {
      -ms-flex-order: 6;
      -webkit-order: 6;
      order: 6;
    }
    .order-xlg-5 {
      -ms-flex-order: 5;
      -webkit-order: 5;
      order: 5;
    }
    .order-xlg-4 {
      -ms-flex-order: 4;
      -webkit-order: 4;
      order: 4;
    }
    .order-xlg-3 {
      -ms-flex-order: 3;
      -webkit-order: 3;
      order: 3;
    }
    .order-xlg-2 {
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
    }
    .order-xlg-1 {
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
    .order-xlg-0 {
      -ms-flex-order: 0;
      -webkit-order: 0;
      order: 0;
    }
  }

  .offset-11 {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }

  .offset-10 {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }

  .offset-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-8 {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }

  .offset-7 {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }

  .offset-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-5 {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }

  .offset-4 {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }

  .offset-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-2 {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }

  .offset-1 {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }

  @media (min-width: 576px) {
    .offset-xs-11 {
      margin-left: 91.66666667%;
      *margin-left: 91.56666667%;
    }
    .offset-xs-10 {
      margin-left: 83.33333333%;
      *margin-left: 83.23333333%;
    }
    .offset-xs-9 {
      margin-left: 75%;
      *margin-left: 74.9%;
    }
    .offset-xs-8 {
      margin-left: 66.66666667%;
      *margin-left: 66.56666667%;
    }
    .offset-xs-7 {
      margin-left: 58.33333333%;
      *margin-left: 58.23333333%;
    }
    .offset-xs-6 {
      margin-left: 50%;
      *margin-left: 49.9%;
    }
    .offset-xs-5 {
      margin-left: 41.66666667%;
      *margin-left: 41.56666667%;
    }
    .offset-xs-4 {
      margin-left: 33.33333333%;
      *margin-left: 33.23333333%;
    }
    .offset-xs-3 {
      margin-left: 25%;
      *margin-left: 24.9%;
    }
    .offset-xs-2 {
      margin-left: 16.66666667%;
      *margin-left: 16.56666667%;
    }
    .offset-xs-1 {
      margin-left: 8.33333333%;
      *margin-left: 8.23333333%;
    }
    .offset-xs-0 {
      margin-left: 0;
      *margin-left: -0.1%;
    }
  }

  @media (min-width: 768px) {
    .offset-sm-11 {
      margin-left: 91.66666667%;
      *margin-left: 91.56666667%;
    }
    .offset-sm-10 {
      margin-left: 83.33333333%;
      *margin-left: 83.23333333%;
    }
    .offset-sm-9 {
      margin-left: 75%;
      *margin-left: 74.9%;
    }
    .offset-sm-8 {
      margin-left: 66.66666667%;
      *margin-left: 66.56666667%;
    }
    .offset-sm-7 {
      margin-left: 58.33333333%;
      *margin-left: 58.23333333%;
    }
    .offset-sm-6 {
      margin-left: 50%;
      *margin-left: 49.9%;
    }
    .offset-sm-5 {
      margin-left: 41.66666667%;
      *margin-left: 41.56666667%;
    }
    .offset-sm-4 {
      margin-left: 33.33333333%;
      *margin-left: 33.23333333%;
    }
    .offset-sm-3 {
      margin-left: 25%;
      *margin-left: 24.9%;
    }
    .offset-sm-2 {
      margin-left: 16.66666667%;
      *margin-left: 16.56666667%;
    }
    .offset-sm-1 {
      margin-left: 8.33333333%;
      *margin-left: 8.23333333%;
    }
    .offset-sm-0 {
      margin-left: 0;
      *margin-left: -0.1%;
    }
  }

  @media (min-width: 992px) {
    .offset-md-11 {
      margin-left: 91.66666667%;
      *margin-left: 91.56666667%;
    }
    .offset-md-10 {
      margin-left: 83.33333333%;
      *margin-left: 83.23333333%;
    }
    .offset-md-9 {
      margin-left: 75%;
      *margin-left: 74.9%;
    }
    .offset-md-8 {
      margin-left: 66.66666667%;
      *margin-left: 66.56666667%;
    }
    .offset-md-7 {
      margin-left: 58.33333333%;
      *margin-left: 58.23333333%;
    }
    .offset-md-6 {
      margin-left: 50%;
      *margin-left: 49.9%;
    }
    .offset-md-5 {
      margin-left: 41.66666667%;
      *margin-left: 41.56666667%;
    }
    .offset-md-4 {
      margin-left: 33.33333333%;
      *margin-left: 33.23333333%;
    }
    .offset-md-3 {
      margin-left: 25%;
      *margin-left: 24.9%;
    }
    .offset-md-2 {
      margin-left: 16.66666667%;
      *margin-left: 16.56666667%;
    }
    .offset-md-1 {
      margin-left: 8.33333333%;
      *margin-left: 8.23333333%;
    }
    .offset-md-0 {
      margin-left: 0;
      *margin-left: -0.1%;
    }
  }

  @media (min-width: 1200px) {
    .offset-lg-11 {
      margin-left: 91.66666667%;
      *margin-left: 91.56666667%;
    }
    .offset-lg-10 {
      margin-left: 83.33333333%;
      *margin-left: 83.23333333%;
    }
    .offset-lg-9 {
      margin-left: 75%;
      *margin-left: 74.9%;
    }
    .offset-lg-8 {
      margin-left: 66.66666667%;
      *margin-left: 66.56666667%;
    }
    .offset-lg-7 {
      margin-left: 58.33333333%;
      *margin-left: 58.23333333%;
    }
    .offset-lg-6 {
      margin-left: 50%;
      *margin-left: 49.9%;
    }
    .offset-lg-5 {
      margin-left: 41.66666667%;
      *margin-left: 41.56666667%;
    }
    .offset-lg-4 {
      margin-left: 33.33333333%;
      *margin-left: 33.23333333%;
    }
    .offset-lg-3 {
      margin-left: 25%;
      *margin-left: 24.9%;
    }
    .offset-lg-2 {
      margin-left: 16.66666667%;
      *margin-left: 16.56666667%;
    }
    .offset-lg-1 {
      margin-left: 8.33333333%;
      *margin-left: 8.23333333%;
    }
    .offset-lg-0 {
      margin-left: 0;
      *margin-left: -0.1%;
    }
  }

  @media (min-width: 1600px) {
    .offset-xlg-11 {
      margin-left: 91.66666667%;
      *margin-left: 91.56666667%;
    }
    .offset-xlg-10 {
      margin-left: 83.33333333%;
      *margin-left: 83.23333333%;
    }
    .offset-xlg-9 {
      margin-left: 75%;
      *margin-left: 74.9%;
    }
    .offset-xlg-8 {
      margin-left: 66.66666667%;
      *margin-left: 66.56666667%;
    }
    .offset-xlg-7 {
      margin-left: 58.33333333%;
      *margin-left: 58.23333333%;
    }
    .offset-xlg-6 {
      margin-left: 50%;
      *margin-left: 49.9%;
    }
    .offset-xlg-5 {
      margin-left: 41.66666667%;
      *margin-left: 41.56666667%;
    }
    .offset-xlg-4 {
      margin-left: 33.33333333%;
      *margin-left: 33.23333333%;
    }
    .offset-xlg-3 {
      margin-left: 25%;
      *margin-left: 24.9%;
    }
    .offset-xlg-2 {
      margin-left: 16.66666667%;
      *margin-left: 16.56666667%;
    }
    .offset-xlg-1 {
      margin-left: 8.33333333%;
      *margin-left: 8.23333333%;
    }
    .offset-xlg-0 {
      margin-left: 0;
      *margin-left: -0.1%;
    }
  }

  .wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .no-wrap {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .no-wrap [class*='col-'] {
    -ms-flex-negative: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
  }

  .wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    -webkit-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .direction-row {
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .direction-row-reverse {
    -ms-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .direction-column {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .direction-column-reverse {
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .align-start {
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .align-end {
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  .align-end [class*='col-'] {
    vertical-align: bottom;
  }

  .align-center {
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .align-center [class*='col-'] {
    vertical-align: middle;
  }

  .align-baseline {
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
  }

  .align-content-start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .align-content-end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .align-content-end [class*='col-'] {
    vertical-align: bottom;
  }

  .align-content-center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }

  .align-content-space-between {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    align-content: space-between;
  }

  .align-content-space-around {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    align-content: space-around;
  }

  .align-self-stretch {
    -ms-flex-item-align: stretch;
    -webkit-align-self: stretch;
    align-self: stretch;
  }

  .align-self-start {
    -ms-flex-item-align: start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }

  .align-self-end {
    -ms-flex-item-align: end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    vertical-align: bottom;
  }

  .align-self-center {
    -ms-flex-item-align: center;
    -webkit-align-self: center;
    align-self: center;
    vertical-align: middle;
  }

  .align-self-baseline {
    -ms-flex-item-align: baseline;
    -webkit-align-self: baseline;
    align-self: baseline;
    vertical-align: baseline;
  }

  .justify-start {
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .justify-start.grid {
    text-align: left;
  }

  .justify-end {
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .justify-end.grid {
    text-align: right;
    -moz-text-align-last: right;
    text-align-last: right;
  }

  .justify-end.grid [class*='col-'] {
    text-align: left;
    text-align: start;
    -moz-text-align-last: left;
    -moz-text-align-last: start;
    text-align-last: left;
    text-align-last: start;
  }

  .justify-center {
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .justify-center.grid {
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
  }

  .justify-center.grid [class*='col-'] {
    text-align: left;
    text-align: start;
    -moz-text-align-last: left;
    -moz-text-align-last: start;
    text-align-last: left;
    text-align-last: start;
  }

  .justify-space-between {
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .justify-space-between.grid {
    text-align: justify;
    -moz-text-align-last: justify;
    text-align-last: justify;
  }

  .justify-space-between.grid [class*='col-'] {
    text-align: left;
    text-align: start;
    -moz-text-align-last: left;
    -moz-text-align-last: start;
    text-align-last: left;
    text-align-last: start;
  }

  .justify-space-around {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .justify-space-around.grid {
    text-align: justify;
    -moz-text-align-last: justify;
    text-align-last: justify;
  }

  .justify-space-around.grid [class*='col-'] {
    text-align: left;
    text-align: start;
    -moz-text-align-last: left;
    -moz-text-align-last: start;
    text-align-last: left;
    text-align-last: start;
  }

  .grid-bleed [class*='col-'] {
    padding: 0;
  }

  .col-grid {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .col-grid.direction-row {
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .col-bleed {
    padding: 0;
  }

  .col-bleed-x {
    padding: 15px 0;
  }

  .col-bleed-y {
    padding: 0 15px;
  }

  .flex-img {
    display: block;
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    height: auto;
    width: 100%;
    *width: auto;
  }

  .flex-footer {
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;
  }

  .flex-footer > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 575px) {
    .hidden-xxs {
      display: none;
    }
  }

  @media (min-width: 576px) {
    .hidden-xs-up {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .hidden-xs-down {
      display: none;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .hidden-xs {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .hidden-sm-up {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .hidden-sm-down {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .hidden-md-up {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    .hidden-md-down {
      display: none;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    .hidden-lg-up {
      display: none;
    }
  }

  @media (max-width: 1599px) {
    .hidden-lg-down {
      display: none;
    }
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    .hidden-lg {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    .hidden-xlg {
      display: none;
    }
  }

  .flex {
    display: flex;
  }
}
