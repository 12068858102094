.wrapper {
  position: relative;
  z-index: 1;
  padding: 0 25px 0 93px;

  @media screen and (max-width: 1000px) {
    padding: 0 15px;
  }

  .downloadLinks {
    margin-left: auto;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 10px;
    }

    .downloadButton {
      img {
        margin-left: 5px;
        height: 36px;
      }
    }
  }
}

.leftBlock {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 10px 0;
    height: auto;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
