.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 20px;
  min-height: 400px;
}

.loader {
  margin: auto;
}

.value {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  color: #3aaa45;
  margin-bottom: 8px;
  text-align: center;
}

.title {
  font-size: 19px;
  text-align: center;
}

.claimTitle {
  margin-top: 12px;
  margin-bottom: 16px;
}

.claimRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;

  &:nth-child(odd) {
    background-color: #e7eaf2;
  }
}

.claimRowLabel {
  margin-right: 38px;
  font-size: 18px;
  line-height: 24px;
}

.countdown {
  padding-top: 20px;
  display: flex;
  align-items: center;

  &.dark {
    .timer {
      background-color: transparent;
    }
  }

  .tooltipIcon {
    margin-top: -18px;
  }
}

.row {
  width: 100%;
  padding: 0px 40px 20px;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  a {
    color: inherit;
  }
}

.description {
  font-size: 16px;
  line-height: 19px;
  padding: 22px 40px 20px;
  word-break: break-word;

  b {
    font-weight: 600;
  }
}

.info {
  width: 100%;
  padding-top: 18px;
  background: #e7eaf2;
  border-radius: 32px 32px 0 0;
}

.tooltipIcon {
  display: flex;
}

.rowLabel {
  display: flex;
  min-width: 100px;
  margin-right: 35px;
  align-items: center;
}

.rowValue {
  text-align: right;
}

.errorTitle {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.errorText {
  text-align: center;
  padding: 17px 20px 20px 20px;
}

.tooltip {
  width: 100%;
  height: 100%;
}

.dark {
  .claimRow,
  .title,
  .description,
  .info {
    color: #ffffff;
  }
  /*css for countdown*/

  .body {
    background-color: #302e2d;
  }

  .claimRow,
  .info {
    &:nth-child(odd) {
      background-color: #1f2535;
    }
  }
}
