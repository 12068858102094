.dialog {
  :global {
    .MuiPaper-root {
      border-radius: 18px;
      background-color: transparent;
      width: 100%;
      max-width: 812px;
      margin: 15px;
    }
  }

  &.dark {
    .body {
      background: #302e2d;
    }
    .block {
      color: #ffffff;
      background: #434E69;
    }
    .disconnect {
      border-color: #ffffff;
      color: #ffffff;
    }
    .control {
      &:hover {
        color: #ffffff;
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
    .balanceTableHeader {
      color: #ffffff;
    }
  }
}

.container {
  width: 100%;

  .disconnect {
    cursor: pointer;
    background: transparent;
    border: 1px solid #ae528b;
    font-weight: 400;
    color: #ae528b;
    font-size: 17px;
    line-height: 21px;
    padding: 3px 12px;
    border-radius: 10px;
    outline: none;
    min-width: auto;
    margin-left: auto;
    letter-spacing: normal;
    text-transform: none;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 12px;
      width: 100%;
    }
  }
}

.header {
  position: relative;
  background: #1C2230;
  display: flex;
  min-height: 78px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(249.54deg,#ff97d6 44.37%,#f8b994 82.84%);
    display: block;
  }
}

.headerTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #F1F3F9;
  padding: 30px 35px 38px 35px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  @media screen and (max-width: 1000px) {
    padding: 14px;
  }
}

.block {
  background: #E4E6ED;
  max-width: 422px;
  margin: 0 auto 36px;
  border-radius: 15px;
  padding: 15px 15px 11px 15px;
}

.blockHeader {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.blockHeaderTitle {
  font-size: 17px;
  line-height: 32px;
  margin-bottom: 4px;
}

.address {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 41px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 6px;
    line-height: 35px;
    margin-top: 6px;
  }
}

.addressIcon {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  margin-right: 11px;
}

.addressText {
  font-size: 22px;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
}

.controls {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.control {
  cursor: pointer;
  font-size: 17px;
  color: #9AA0AD;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  min-height: 32px;
  padding: 0;
  background: transparent;
  transition: all .2s ease-out;
  text-decoration: none;

  @media screen and (max-width: 1000px) {
    font-size: 15px;
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  &:hover {
    color: #1C2230;
    svg {
      path {
        fill: #1C2230;
      }
    }
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    margin-right: 8px;

    path {
      transition: all .2s ease-out;
    }
  }
}

.MuiPaper-root {
  background: transparent;
  box-shadow: none;
}

.balanceTableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  padding: 0px 22px 8px 16px;
  font-size: 17px;
  line-height: 27px;
}
