.body {
  display: flex;
  flex-direction: column;
  padding: 25px 18px;
  background-color: #f1f3f9;
  min-height: 288px;

  &.success {
    padding-top: 15px;

    .value {
      margin-bottom: 22px;
    }
  }
}

.loader {
  margin: auto;
}

.title {
  font-size: 19px;
  line-height: 34px;
  text-align: center;
}

.value {
  position: relative;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: #3aaa45;
  margin-bottom: 10px;
}

.successIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 131px;
  background: #ebeef5;
  border-radius: 100px;
  margin: 0 auto 15px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    background: #e4e7ee;
    border-radius: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 56px);
    height: calc(100% - 56px);
    background: #43af64;
    border-radius: 100px;
  }

  img {
    position: relative;
    z-index: 2;
  }
}

.description {
  font-size: 19px;
  text-align: center;
  line-height: 33px;
  margin-bottom: 40px;
}

.bottomBlock {
  background: #e7eaf2;
  border-radius: 18px;
  padding: 22px 34px;
}

.checkbox {
  margin-bottom: 22px;
}

.checkboxText {
  width: 319px;
}

.inputLabel {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  background: #fdfdfd;
  border: 1px solid #d2d6e8;
  height: 40px;
  font-size: 18px;
  border-radius: 7px;
  padding: 0 12px;
  margin-bottom: 25px;
}

.buttonContainer {
  display: flex;
  justify-content: center;

  .button {
    min-width: 220px;
  }
}

.errorTitle {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 17px;
}

.errorText {
  text-align: center;
}

.dark {
  .body,
  .title,
  .table {
    background-color: #302e2d;
  }

  .title,
  .description {
    color: #ffffff;
  }

  .success {
    .successIcon {
      background: inherit;

      &:before {
        background: inherit;
      }

      &:after {
        width: calc(100% - 35px);
        height: calc(100% - 35px);
      }
    }
  }
}
