.dialog {
  :global {
    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 100px;

      
      @media screen and (max-width: 1000px) {
        align-items: center;
        margin-top: 0;
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      width: 100%;
      max-width: 510px;
      margin: 15px;
      max-height: calc(100% - 164px);

      @media screen and (max-width: 1000px) {
        max-height: calc(100% - 64px);
      }
    }
  }

  p {
    margin: 0;
  }
}

.container {
  position: relative;
}

.dark {
  .body {
    background-color: #10131c;
    color: #fff;
  }

  .footer {
    background-color: #282626;
  }

  .line {
    color: #fff;
  }
}

.header {
  position: relative;
  background: #1C2230;
  display: flex;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  z-index: 3;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(249.54deg,#ff97d6 44.37%,#f8b994 82.84%);
    display: block;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #fff;
  padding: 50px 40px 20px 40px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    padding: 14px;
  }
}

.text {
  font-size: 19px;
  line-height: 150%;
}

.link {
  text-decoration: none;
  color: #ae528b;
}

.footer {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:  0 0 13px 13px;
  background: #F1F3F9;
}

.lineWrapper {
  width: 100%;
  margin-bottom: 20px;

  .line:not(:last-child) {
    margin-bottom: 15px;
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1C2230;
  line-height: 170%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.lineText {
  font-weight: normal;
  font-size: 18px;
}

.arrowImg {
  width: 23px;
  margin-left: 9px;
  margin-right: 9px;
}

.lineValue {
  font-weight: 600;
  font-size: 19px;
}

.progressBar {
  width: 100%;
  margin-bottom: 50px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.error {
  text-align: center;
  margin-bottom: 16px;
  color: #ae528b;
}
