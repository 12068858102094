.container {
  cursor: pointer;
  border: 0;
  outline: none;
  z-index: 2;
  display: none;
  flex-direction: column;
  background-color: rgb(249, 250, 255);
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;

  @media screen and (max-width: 1000px) {
    display: flex;
  }

  &.white {
    background-color: rgb(28, 34, 48);
    .line {
      background-color: #ffffff;
    }
  }

  &.active {
    z-index: 51;
    .top {
      transform: rotate(-45deg) translateY(6px) translateX(-5px);
    }
    .center {
      opacity: 0;
    }
    .bottom {
      transform: rotate(45deg) translateY(-6px) translateX(-5px);
    }
  }
}

.line {
  height: 3px;
  width: 28px;
  background-color: #000000;
  transition: all .2s ease-out;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
