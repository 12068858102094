.column {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
