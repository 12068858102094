.link {
  display: flex;
  position: relative;
  text-decoration: none;

  &:last-child {
    margin-top: 40px;

    @media screen and (max-width: 1000px) {
      margin-top: 0;
    }
  }

  &:hover {
    .text {
      opacity: 1;
      visibility: visible;
    }
  }
}

.text {
  position: absolute;
  font-size: 16px;
  color: #fa97d6;
  left: calc(100% + 22px);
  font-weight: 500;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  text-decoration: none;
  transition: opacity .2s ease-out;

  @media screen and (max-width: 1000px) {
    opacity: 1;
    visibility: visible;
    position: static;
    transform: translateY(0);
    top: auto;
    left: auto;
    margin-top: 8px;
  }
}

.linkWrapper {
  width: 100%;
  outline: none;
  border: 0;
}

.linkBody {
  cursor: pointer;
  height: 60px;
  border-radius: 30px 0 0 30px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: auto;
    padding: 8px 0;
    border-radius: 0;
  }
}

.spacer {
  height: 10px; 
  border-radius: 30px 0 0 30px; 
  opacity: 0;

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    opacity: 1;
  }

  &.spacerTop {
    opacity: 1;
  }

  &.spacerBottom {
    opacity: 1;
    .spacerInner {
      border-radius: 0 10px 0 0;
      @media screen and (max-width: 1000px) {
        border-radius: 0;
      }
    }
  }
}

.spacerInner {
  height: 10px; 
  border-radius: 0 0 10px 0; 
  @media screen and (max-width: 1000px) {
    border-radius: 0;
  }
}
