.wrapper {
  display: flex;
  align-items: center;
  color: #1C2230;
  font-weight: normal;
  font-size: 19px;

  @media screen and (max-width: 768px) {
    display: block;
    margin-right: 20px;
  }

  &.dark {
    color: #ffffff;
  }
}

.borrowText {
  flex-shrink: 0;
  margin-right: 15px;
  span {
    margin-left: 8px;
  }
}

.progressBar {
  background: #3A4866;
  border-radius: 5px;
  padding: 4px 5px;
  width: 100%;
  height: 22px;
  flex-grow: 1;
  position: relative;
}

.progressLine {
  background: linear-gradient(90deg, #547ED8 9.91%, #EE367E 107.67%);
  height: 14px;
}

.circle {
  position: absolute;
  background: #3A4866;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 15px;
  position: absolute;
  top: -9px;
  transform: translateX(-50%);
}

.circleFirst {
  left: 25%;
}
.circleSecond {
  left: 50%;
}
.circleThird {
  left: 75%;
}
.circleFourth {
  right: 0;
  transform: translateX(50%);
}

.value {
  flex-shrink: 0;
  margin-left: 32px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.small {

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
  .progressBar {
    border-radius: 11px;
    height: 12px;
    padding: 3px;
    box-sizing: border-box;
  }

  .progressLine {
    border-radius: 6px 0 0 6px;
    height: 6px;
  }
}
