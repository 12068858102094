.modal {
	:global {
		.MuiPaper-root {
			border-radius: 10px;
			background: transparent;

			* {
				box-sizing: border-box;
			}
			
			@media screen and (max-width: 1000px) {
				margin: 10px;
			}
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	max-width: 394px;
	min-height: 556px;
}

.title {
	text-align: center;
	margin-top: 25px;
	margin-bottom: 12px;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;

	@media screen and (max-width: 1000px) {
		margin-top: 20px;
	}
}

.description {
	font-size: 15px;
	line-height: 23px;
	margin: 0 auto;
	max-width: 300px;
	text-align: center;
	margin-bottom: 12px;
}

.list {
	margin: 0 60px 14px 60px;
	padding: 0;

	@media screen and (max-width: 1000px) {
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
  }
}

.item {
	list-style: none;
	padding: 15px 0;
	border-bottom: 1px solid rgba(164, 169, 184, 0.29);

	&.itemDisabled {
		cursor: default;
		pointer-events: none;
	}

	@media screen and (max-width: 1000px) {
		padding: 10px 0;
  }
}

.itemInner {
	text-decoration: none;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 17px;
	line-height: 20px;
	flex-wrap: wrap;
}

.logo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	width: 45px;
	height: 45px;
	margin-right: 28px;
	border: 1px solid #D5D5D5;
	border-radius: 100px;
	@media screen and (max-width: 1000px) {
		margin-right: 15px;
		width: 35px;
		height: 35px;
	}

	img {
		max-width: 90%;
		max-height: 90%;
		object-fit: contain;
	}

	&.active {
		border-color: #ae528b;
	}
}

.status {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 10px;
	height: 10px;
	background-color: #ae528b;
	border-radius: 100px;

	&.active {
		background-color: #3AAA45;
	}
}

.arrow {
	margin-left: auto;
}

.list li:last-child {
	border-bottom: 0
}

.disabledText {
	font-size: 17px;
	line-height: 20px;
	margin-left: auto;
}

.back {
	cursor: pointer;
	display: flex;
	border: 0;
	outline: none;
	background: transparent;
	position: absolute;
	left: 14px;
	top: 14px;
}

.close {
	cursor: pointer;
	position: absolute;
	top: 7px;
	right: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	background-color: transparent;
	outline: none;
	border: 0;
	transition: opacity .2s ease-out;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}
}

.loading {
	text-align: center;
	height: 100px;
	margin-top: 100px;

	@media screen and (max-width: 1000px) {
		height: 100px;
	}
}

.loading img {
	width: 50px;
	height: 50px;
}

.footer {
	margin-top: auto;
	border-top: 1px solid rgba(164, 169, 184, 0.29);
	background: inherit;
	font-size: 15px;
	line-height: 16px;
	text-align: center;
	color: #606371;
	padding: 14px 0;

	a {
		color: #E22D73;
		text-decoration: none;
	}
}
