.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
